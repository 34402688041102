import { ElMessage } from "element-plus"
import file2base64 from "@/utils/file2base64"
import { useStore } from "vuex"
import md5 from "md5"
import { segmentedUploadFile } from "@/utils/ossProduct"

export default function () {
  const beforeUpload = async (file, obj) => {
    handleUpload(file, obj)
    return false
  }

  const store = useStore()
  const handleUpload = async (file, obj) => {
    const { type, size, name } = file
    if (type !== "image/jpeg" && type !== "image/png") {
      ElMessage.error("Only PNG or JPEG images are supported")
      return
    }
    if (size > 20 * 1024 * 1024) {
      ElMessage.error("Up to 20M files are supported")
      return
    }
    const base64 = await file2base64(file)
    const md5String = md5(base64)
    obj.imgLoading = true

      //上传s3  并获取图片路径
      try {
        const resData = await segmentedUploadFile(
          file,
          obj.user_code,
          md5String,
          obj.design_id
        )
        const { Key, Location } = resData
        obj.imgUrl = Location
        obj.larger = Key
      } catch (e) {
        obj.imgLoading = false
        ElMessage.error(e)
      }
 
    obj.imgLoading = false
  }

  return {
    beforeUpload,
  }
}
