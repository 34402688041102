<script setup>
import { ref } from 'vue'
import uploadImg from '@/views/productEdit/hooks/uploadImg.js'
import { Plus, Loading, Delete, WarningFilled } from '@element-plus/icons'
import { ElMessage } from 'element-plus'
import { coverImg } from '@/api/design'
let props = defineProps({
  uploadImageShow: {
    type: Boolean,
    default: () => false,
  },
  tableData: {
    type: Array,
    default: () => [],
  },
})

let emit = defineEmits(['cancel', 'updateCoverImg'])

let cancel = () => {
  emit('cancel')
  colorList.value = []
  arr.value = []
}
let { beforeUpload } = uploadImg()
let beforeUploadImg = (file, item) => {
  beforeUpload(file, item)
  return Promise.reject()
}

let colorList = ref([])
let isDefault = ref(null)
let open = () => {
  colorList.value = JSON.parse(JSON.stringify(props.tableData)).map((e) => {
    if (!!e.is_default) {
      isDefault.value = e.id
    }
    e.imgUrl = e?.thumb_img?.img400 || e?.img?.[0]?.img400
    e.larger = e?.thumb_img?.original || e?.img?.[0]?.original
    e.imgLoading = false
    return e
  })

  if (!isDefault.value) {
    isDefault.value = colorList.value[0].id
  }
}
let configLoading = ref(false)
let arr = ref([])
let confirm = async () => {
  configLoading.value = true
  colorList.value.forEach((e) => {
    if (!!e.larger) {
      let obj = {
        imgUrl: e.larger,
        designColorId: e.id,
        isDefault: e.id === isDefault.value ? 1 : 0,
      }
      arr.value.push(obj)
    }
  })

  if (arr.value.length > 0) {
    try {
      const { message, data } = await coverImg({
        designId: colorList.value[0].design_id,
        cover: arr.value,
      })
      ElMessage.success(message)
      emit('updateCoverImg', data)
      cancel()
    } catch (error) {
      ElMessage.error(error?.message || error)
    }
  } else {
    cancel()
  }

  configLoading.value = false
}


</script>

<template>
  <el-dialog
    :model-value="uploadImageShow"
    title="Upload Customize Image"
    width="30%"
    :before-close="cancel"
    @open="open"
    :close-on-click-modal="false"
  >
    <div>
      <div class="tips">
        <el-icon class="warningFilled"><WarningFilled /></el-icon>
        <div style="margin-left: 10px">
          Recommended upload image size: 1600 * 1600 px
        </div>
      </div>
      <div class="roll">
        <el-radio-group
          v-model="isDefault"
          style="width: 100%"
        >
          <div v-for="item in colorList" :key="item.id">
            <div class="color_col">
              <el-tag color="#fff">{{ item.name }}</el-tag>
              <div class="img">
                <el-upload
                  class="upload"
                  action=""
                  list-type="picture"
                  :before-upload="(e) => beforeUploadImg(e, item)"
                  v-if="!item.imgUrl"
                  :disabled="item.imgLoading"
                >
                  <el-icon
                    class="plus"
                    :class="item.imgLoading ? 'is-loading' : ''"
                  >
                    <Plus v-if="!item.imgLoading" />
                    <Loading v-else />
                  </el-icon>
                </el-upload>
                <div class="imgae" v-else>
                  <el-image :src="item.imgUrl"></el-image>
                  <div class="delete">
                    <el-icon class="icon_delete" @click="item.imgUrl = ''">
                      <Delete />
                    </el-icon>
                  </div>
                </div>
              </div>
              <el-radio :label="item.id" size="large">Set as homepage</el-radio>
            </div>
          </div>
        </el-radio-group>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel">Cancel</el-button>
        <el-button type="primary" :loading="configLoading" @click="confirm"
          >Confirm</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
.roll {
  max-height: 460px;
  overflow-y: auto;
}
.tips {
  width: 100%;
  height: 32px;
  background: rgba(30, 171, 127, 0.03);
  border: 1px solid rgba(30, 171, 127, 0.35);
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1eab7f;
  line-height: 32px;
  display: flex;
  margin-bottom: 30px;
  .warningFilled {
    margin: 0 20px 0 10px;
    font-size: 16px;
    line-height: 35px;
  }
}
:deep(.el-tag) {
  border-color: transparent;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  margin-bottom: 14px;
}
.icon_delete {
  width: 100%;
  height: 100%;
  line-height: 100px;
  text-align: center;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}
.delete {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
}
.imgae {
  position: relative;
  width: 100%;
  height: 100%;
  &:hover .delete {
    display: block;
  }
}
.color_col {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.img {
  width: 100px;
  height: 100px;
  background: #fafafa;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 11px;
}
.plus {
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 20px;
}
.upload {
  width: 100%;
  height: 100%;
  border: 1px dashed #cdd0d6;
  &:hover {
    border-color: #409eff;
  }
}
</style>
