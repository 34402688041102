<script setup>
import { Back } from "@element-plus/icons";
import { useRouter } from "vue-router";

const props = defineProps({
  text: {
    type: String,
    default: "Back",
  },
});

const $router = useRouter();
const handleBack = () => {
  $router.back();
};
</script>

<template>
  <div class="back_arrow" @click="handleBack">
    <el-icon class="back_icon">
      <back />
    </el-icon>
    <span class="back_text">{{ text }}</span>
  </div>
</template>

<style scoped lang="scss">
.back_arrow {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  width: 360px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  .back_icon {
    font-size: 24px;
  }
  .back_text {
    font-size: 18px;
    margin-left: 10px;
  }
}
</style>
