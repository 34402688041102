import request from "@/utils/request.js"

export function finishedProductDetails ({ designId }) {
  return request({
    url: "/design/getFinishedProductDetails",
    method: "get",
    params: {
      designId,
    },
  })
}

//编辑设计成品

export function editFinishedProduct ({
  designId,
  // cover,
  productName,
  describe,
  price,
  isEnableDesigner,
  tags,
  isRecommend,
  isPreview,
  remark
}) {
  return request({
    url: "/design/editFinishedProduct",
    method: "PUT",
    data: {
      designId,
      // cover,
      productName,
      describe,
      price,
      isEnableDesigner: isEnableDesigner ? 1 : 0,
      tags: tags || undefined,
      isRecommend: isRecommend ? 1 : 0,
      isPreview: !!isPreview ? 1 : 0,
      remark
    },
  })
}

//刊登 shopify
export function publishedShopifyProducts ({ designId, storeCode }) {
  return request({
    url: "/partner/publishProduct",
    method: "POST",
    data: {
      designId,
      storeCode,
    },
  })
}

//更新 shopify
export function editShopifyProduct ({ designId, productName, describe, price }) {
  return request({
    url: "/publish/editShopifyProduct",
    method: "put",
    data: {
      designId,
      productName,
      describe,
      price,
    },
  })
}

//获取标签列表

export function getTagProductTemplateDisplayList () {
  return request({
    url: "/design/getTagProductTemplateDisplayList",
    method: "get",
  })
}

//重新设计

export function getRebootDesign ({ designId }) {
  return request({
    url: "/design/rebootDesign",
    method: "put",
    data: {
      designId,
    },
  })
}

//获取单个图片合成成功的数据
export function getFinishedProductMergeStatus ({ designIds }) {
  return request({
    url: "/design/getFinishedProductMergeStatus",
    method: "GET",
    params: {
      designIds,
    },
  })
}

//获取设计产品导出文件url
export function getDesignerProductExportUrl ({ storeCode, type, designId }) {
  return request({
    url: "/design/getDesignerProductExportUrl",
    method: "POST",
    data: {
      storeCode,
      type, //类型：1当前页，2所有
      designId: Number(type) === 1 ? designId : undefined,
    },
  })
}



// 成品上传图片

export function coverImg ({
  designId,
  cover
}) {
  return request({
    url: "/design/systemTemplateCoverImg",
    method: "post",
    data: {
      designId,
      cover
    }
  })
}