<script setup>
import {
  ref,
  reactive,
  computed,
  nextTick,
  watchEffect,
  defineProps,
} from 'vue'
import { ElMessageBox } from 'element-plus'

const props = defineProps({
  tableData: Array,
  productDetails: Object,
  specification: Array,
})

// 处理数据及响应
let modifyType = ref('price') // price || profit || profitMargin

watchEffect(() => {
  props.tableData.forEach((item1) => {
    item1.design_item.forEach((item2) => {
      if (modifyType.value === 'price') {
        item2.profit = (Number(item2.price) - Number(item2.cost)).toFixed(2)
        item2.profitMargin = Number(
          ((item2.profit / item2.cost) * 100).toFixed(2)
        )
      } else if (modifyType.value === 'profit') {
        item2.price = (Number(item2.cost) + Number(item2.profit)).toFixed(2)
        item2.profitMargin = Number(
          ((item2.profit / item2.cost) * 100).toFixed(2)
        )
      } else if (modifyType.value === 'profitMargin') {
        item2.profit = Number(
          ((item2.profitMargin / 100) * item2.cost).toFixed(2)
        )
        item2.price = (Number(item2.cost) + Number(item2.profit)).toFixed(2)
      }
    })
  })
})

// 处理多选
const tableRef = ref(null)
const multipleSelection = reactive({
  value: [],
})
const selectionIdArray = computed(() => {
  return multipleSelection.value.map((item) => item.id)
})

const handleSelectionChange = (selection) => {
  multipleSelection.value = selection.filter((item) => item.isChild)
}

const rowSelect = (selection, row) => {
  nextTick(() => {
    if (row.isChild) {
      // 子节点
      const pid = row.pid
      const parentRow = props.tableData.find((item) => item.id === pid)
      let hasChildSelected = false
      parentRow.design_item.forEach((item) => {
        if (selection.some((row) => row.id === item.id)) {
          hasChildSelected = true
        }
      })
      tableRef.value.toggleRowSelection(parentRow, hasChildSelected)
    } else if (row.design_item) {
      // 父节点
      const isSelected = selection.some((item) => item.id === row.id)

      row.design_item.forEach((child) => {
        tableRef.value.toggleRowSelection(child, isSelected)
      })
    }
  })
}

const selectAll = (selection) => {
  nextTick(() => {
    let isAllcancel = false
    selection.forEach((item) => {
      if (!item.isChild && item.design_item instanceof Array) {
        isAllcancel = true
        item.design_item.forEach((child) => {
          tableRef.value.toggleRowSelection(child, true)
        })
      }
    })
    if (!isAllcancel) {
      tableRef.value.clearSelection()
    }
  })
}

// 编辑按钮
const btnDisabled = computed(() => !multipleSelection.value.length)
const batchModify = ({ type, value }) => {
  modifyType.value = type
  props.tableData.forEach((item1) => {
    item1.design_item.forEach((item2) => {
      if (selectionIdArray.value.includes(item2.id)) {
        item2[type] = Number(value)
      }
    })
  })
}

const handleEdit = (type) => {
  let typeName = ''
  if (type === 'price') {
    typeName = 'retail price'
  } else if (type === 'profit') {
    typeName = 'profit'
  } else if (type === 'profitMargin') {
    typeName = 'profit margin'
  }
  ElMessageBox.prompt(
    `The will update ${typeName} for all ${selectionIdArray.value.length} variants selected.`,
    `Update ${typeName}`,
    {
      confirmButtonText: 'Apply',
      cancelButtonText: 'Cancel',
      inputPattern: /^(-?\d+)(\.\d+)?$/,
      inputErrorMessage: 'Invalid input',
    }
  )
    .then(({ value }) => {
      batchModify({ type, value })
    })
    .catch(() => {})
}

// 获取最终数据
const getData = () => {
  const price = []
  props.tableData.forEach((item1) => {
    item1.design_item.forEach((item2) => {
      price.push({
        itemId: item2.id,
        sellingPrice: item2.price,
      })
    })
  })
  return { price }
}

defineExpose({
  getData,
})

let setSize = computed(() => {
  let name = ''
  props.specification.forEach((e) => {
    if (Number(e.level) === 2) {
      name = e.name
    }
  })
  return name
})

let setColor = computed(() => {
  let name = ''
  props.specification.forEach((e) => {
    if (Number(e.level) === 1) {
      name = e.name
    }
  })
  return name
})

let treeProps = reactive({
  hasChildren: 'hasChildren',
  children: 'design_item',
})
</script>
<template>
  <div class="btn_wrap">
    <el-button
      type="primary"
      :disabled="btnDisabled"
      @click="handleEdit('price')"
      >Edit price</el-button
    >
    <el-button
      type="primary"
      :disabled="btnDisabled"
      @click="handleEdit('profit')"
      >Edit profit</el-button
    >
    <el-button
      type="primary"
      :disabled="btnDisabled"
      @click="handleEdit('profitMargin')"
      >Edit profit margin</el-button
    >
  </div>

  <el-table
    ref="tableRef"
    :data="tableData"
    row-key="id"
    @selection-change="handleSelectionChange"
    @select="rowSelect"
    @select-all="selectAll"
    :tree-props="treeProps"
    :default-expand-all="true"
  >
    <el-table-column type="selection" align="center" />
    <el-table-column :label="setColor" min-width="100">
      <template #default="{ row }">
        <span>{{ !!row.hasChildren ? row.color_name : row.name }}</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="size_name"
      :label="setSize"
      align="center"
      min-width="80"
    />
    <el-table-column label="SKU" align="center" min-width="150">
      <template #default="{ row }">
        <div>
          {{ row.code }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Cost" align="center" min-width="80">
      <template #default="{ row }">
        <div>
          <span v-if="row.hasChildren">$</span>
          {{ row.cost }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Retail Price" align="center" min-width="150">
      <template #default="scope">
        <el-input
          v-if="scope && scope.row.hasChildren"
          v-model.number="scope.row.price"
          type="number"
          @focus="modifyType = 'price'"
        >
          <template #prepend>
            <div style="color: #000">USD</div>
          </template>
        </el-input>
      </template>
    </el-table-column>
    <el-table-column label="Profit" align="center" min-width="150">
      <template #default="scope">
        <el-input
          v-if="scope && scope.row.hasChildren"
          v-model.number="scope.row.profit"
          type="number"
          @focus="modifyType = 'profit'"
        >
          <template #prepend>
            <div style="color: #000">USD</div>
          </template>
        </el-input>
      </template>
    </el-table-column>
    <el-table-column label="Profit margin" align="center" min-width="150">
      <template #default="scope">
        <!-- {{scope.row}} -->
        <el-input
          v-if="scope && scope.row.hasChildren"
          v-model.number="scope.row.profitMargin"
          type="number"
          @focus="modifyType = 'profitMargin'"
        >
          <template #append>
            <div style="color: #000">%</div>
          </template>
        </el-input>
      </template>
    </el-table-column>
  </el-table>
</template>

<style scoped lang="scss">
.btn_wrap {
  text-align: right;
}
:deep(.el-input-group__prepend) {
  padding: 0 5px;
}
:deep(.el-input__inner) {
  padding: 0 5px;
}
:deep(.el-input-group__append) {
  padding: 0 5px;
}
</style>
