<script setup>
import { ref, reactive, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import backArrow from '@/components/backArrow'
import copyrightInfo from '@/components/copyrightInfo'
import imageSelector from './components/imageSelector'
import descInput from './components/descInput'
import priceTable from './components/priceTable'
import { useRoute } from 'vue-router'
import {
  finishedProductDetails,
  editFinishedProduct,
  publishedShopifyProducts,
  editShopifyProduct,
} from '@/api/design.js'
import { deleteShopifyProduct } from '@/api/myStore'

// 请求数据
const designId = useRoute().params.id

//获取编辑成品详情
onMounted(() => {
  getFinishedProductDetails()
})
let productDetails = reactive({
  info: {},
})

let tableData = ref([])

let loading = ref(false)
let priductType = ref('')
let enableDesigner = ref(1)
let templateTags = ref([])
let isOccasions = ref('')
let specification = ref([])
let real_time_preview = ref(0)
let remark = ref('')
let getFinishedProductDetails = async () => {
  loading.value = true
  try {
    const { data } = await finishedProductDetails({
      designId,
    })
    productDetails.info = data
    colorList.list = productDetails.info.design_colors[0]
    colorList.list.img.forEach((e) => {
      colorList.arr.push(e.img)
    })
    cover.value = colorList.list.img[0].img400

    data.design_colors.forEach((item) => {
      item.design_item.forEach((val) => {
        val.profit = 0
        val.profitMargin = 0
        val.cost = (
          Number(val.size.price) + Number(data.product.price)
        ).toFixed(2)
        val.hasChildren = true
        val.isChild = true
        val.pid = item.id
      })
    })
    specification.value = data?.product?.specification
    enableDesigner.value = data.enable_designer
    priductType.value = data?.type
    describeProduct.value = data?.describe
    titleProduct.value = data?.name
    tableData.value = data?.design_colors.map((e) => {
      e.user_code = data?.user_code
      return e
    })
    templateTags.value = data?.tags || []
    isOccasions.value = data?.is_recommend_occasions
    real_time_preview.value = data?.real_time_preview
    remark.value = data?.remark
  } catch (error) {
    ElMessage.error(error || error?.message)
  }
  loading.value = false
}
//#region 选择封面
const cover = ref()

const viewIndex = ref(0)
const colorIndex = ref(0)

//成品图片
const handleChnageViewIndex = (index, item) => {
  viewIndex.value = index
  cover.value = item.img400
}

//颜色
// const handleChnageColorIndex = (index, item) => {
//   colorIndex.value = index;
// };

let colorList = reactive({
  list: [],
  arr: [],
})
let sizeName = (item, index) => {
  colorList.arr = []
  cover.value = item.img[0].img400
  colorIndex.value = index
  colorList.list = item
  item.img.forEach((e) => {
    colorList.arr.push(e.img)
  })
  viewIndex.value = 0
}
//#endregion

// 填写描述文本
let titleProduct = ref('')
let describeProduct = ref('')

// 保存数据
const descInputIns = ref(null)
const priceTableIns = ref(null)

const getData = async () => {
  const imageSelectorData = {
    cover: cover.value,
  }
  const descInputData = await descInputIns.value.getData()
  const priceTableData = priceTableIns.value.getData()
  return Object.assign({}, imageSelectorData, descInputData, priceTableData)
}

let loadingShow = ref(false)
let loadingIsShow = ref(false)
//保存
const handleSaveDraft = async (e) => {
  loadingIsShow.value = true
  loadingShow.value = true
  const data = await getData()
  const {
    productName,
    describe,
    price,
    isEnableDesigner,
    tags,
    isRecommend,
    isPreview,
    remark,
  } = data
  // let colorArr = []
  // productDetails.info.design_colors.forEach((item, index) => {
  //   if (colorIndex.value === index) {
  //     colorArr.push({
  //       designColorId: item.id,
  //       img: item.img[0].original,
  //     })
  //   }
  // })

  try {
    const { message } = await editFinishedProduct({
      designId: productDetails.info.id,
      // cover: colorArr,
      productName,
      describe,
      price,
      isEnableDesigner,
      tags,
      isRecommend,
      isPreview,
      remark
    })
    if (e) {
      ElMessage({
        type: 'success',
        message,
      })
    }
  } catch (error) {
    ElMessage.error(error)
  }
  if (e) {
    loadingIsShow.value = false
    loadingShow.value = false
  }
}

//刊登 发布 更新 is_issue
const handlePublish = async () => {
  loadingShow.value = true
  loadingIsShow.value = true
  try {
    const data = await getData()
    const { describe, price, productName } = data
    if (data.productName.trim() === '') {
      return ElMessage.error('Description title is required')
    } else {
      if (productDetails.info.is_issue) {
        //已刊登过
        await handleSaveDraft()
        //更新
        const { message } = await deleteShopifyProduct({
          designId: productDetails.info.id,
          topic: 0,
        })

        ElMessage({
          type: 'success',
          message,
        })
      } else {
        // 未刊登
        await handleSaveDraft()
        //刊登
        const { message } = await publishedShopifyProducts({
          designId: productDetails.info.id,
        })
        ElMessage({
          type: 'success',
          message,
        })
      }
    }
  } catch (error) {
    console.log(error)
    ElMessage.error(error)
  }
  loadingShow.value = false
  loadingIsShow.value = false
}

let updateCoverImg = (e) => {
  e.forEach((v) => {
    if (v.design_id === productDetails.info.id) {
      productDetails.info.design_colors.forEach((el) => {
        if (el.id === v.id) {
          el.thumb_img = v.thumb_img
          el.img = v.img
          el.is_default = v.is_default
        }
      })
    }
  })
  cover.value =
    productDetails?.info?.design_colors?.[colorIndex.value]?.img?.[0]?.img400
}
</script>

<template>
  <div class="content" v-loading="loading">
    <div class="title_wrap">
      <div class="title break">{{ productDetails.info.name }}</div>
      <back-arrow text="Back to my store" />
    </div>

    <div class="section">
      <div class="title">Product images</div>
      <div class="body">
        <image-selector
          :cover="cover"
          :view-index="viewIndex"
          :color-index="colorIndex"
          :productDetails="productDetails"
          @change-view-index="handleChnageViewIndex"
          @sizeName="sizeName"
          :colorList="colorList"
          :tableData="tableData"
          @updateCoverImg="updateCoverImg"
        />
      </div>
    </div>

    <div class="section">
      <div class="title">Description</div>
      <div class="body">
        <desc-input
          ref="descInputIns"
          :describeProduct="describeProduct"
          :titleProduct="titleProduct"
          :priductType="priductType"
          :enableDesigner="enableDesigner"
          :templateTags="templateTags"
          :isOccasions="isOccasions"
          :real_time_preview="real_time_preview"
          :remark='remark'
        />
      </div>
    </div>

    <div class="section">
      <div class="title">Edit variants price</div>
      <div class="body">
        <price-table
          ref="priceTableIns"
          :table-data="tableData"
          :productDetails="productDetails"
          :specification="specification"
        />
      </div>
    </div>
  </div>

  <div class="fixed_footer">
    <div class="btn_wrap">
      <el-button @click="handleSaveDraft(true)" :loading="loadingIsShow"
        >Save as draft</el-button
      >
      <el-button type="primary" :loading="loadingShow" @click="handlePublish"
        >Publish</el-button
      >
    </div>

    <copyright-info mode="white" height="50px" />
  </div>
</template>

<style scoped lang="scss">
.content {
  width: 80%;
  max-width: 1120px;
  min-width: 500px;
  margin: 0 auto;
  padding: 20px 0;
  padding-bottom: 20px;

  .title_wrap {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 36px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      white-space: pre-line;
    }
  }
}

.fixed_footer {
  background-color: #fff;
  position: sticky;
  position: -webkit-sticky;
  width: 100%;
  bottom: 0;
  right: 0;
  z-index: 99;
  .btn_wrap {
    width: 80%;
    max-width: 1120px;
    min-width: 500px;
    margin: 0 auto;
    height: 70px;
    padding-top: 15px;
    text-align: right;
  }
}

.section {
  background-color: #fff;
  margin-top: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  .title {
    padding: 20px 30px;
    font-size: 24px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .body {
    padding: 20px 30px;
  }
}
</style>
