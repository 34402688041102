<script setup>
import { encode } from 'js-base64'
import { useStore } from 'vuex'
import { computed, ref, getCurrentInstance } from 'vue'
import Store from 'store'
// import uploadCoverImage from './uploadCoverImage.vue'
import uploadCoverImage from './uploadCoverImage.vue'
const props = defineProps({
  cover: String,
  viewIndex: Number,
  colorIndex: Number,
  productDetails: {
    type: Object,
    default: () => ({}),
  },
  colorList: {
    type: Object,
    default: () => ({}),
  },
  tableData: {
    type: Array,
    default: () => [],
  },
})

let store = useStore()

// 系统账号
let status = computed(() => store?.state?.user?.userInfo?.isAdmin)

//重新编辑按钮显示
let setEditDesign = computed(() => {
  return (e) => {
    return Number(e) === 3 || (!!status.value && Number(e) === 2)
  }
})

const emit = defineEmits([
  'changeViewIndex',
  'changeColorIndex',
  'sizeName',
  'updateCoverImg',
])

//跳转设计器
let setEditDesignUrl = computed(() => {
  //回调地址
  let path = encode(encodeURIComponent(window.location.href))

  //token
  let token
  if (process.env.VUE_APP_CUSTOM_ENV === 'development') {
    token = process.env.VUE_APP_TOKEN
  } else {
    token = Store.get('diyToken')
  }

  // 地址
  let url = process.env.VUE_APP_TEMPLATE_DESIGN

  //id 设计id
  //merchant_id   用户id
  //product_id  产品id
  //store_code   店铺code

  const { id, user_code, product_id, store_code } = props.productDetails.info

  let designPath = `${url}?productId=${product_id}&designId=${id}&backUrl=${path}&token=${token}&storeCode=${store_code}&merchantId=${user_code}`

  return designPath
})

//大图预览
const instance = getCurrentInstance()

let viewerShow = () => {
  const _this = instance.appContext.config.globalProperties

  _this.$viewerApi({
    options: {
      initialViewIndex: props.viewIndex,
    },
    images: props.colorList.arr,
  })
}

//上传封面图
let uploadImageShow = ref(false)
let uploadImage = () => {
  uploadImageShow.value = true
}

let cancel = () => {
  uploadImageShow.value = false
}

let updateCoverImg = (e) => {
  emit('updateCoverImg', e)
}
</script>

<template>
  <div class="image_selector">
    <div class="selector_left">
      <div class="image_container">
        <el-image
          :src="cover"
          style="width: 100%; height: 100%"
          @click="viewerShow"
        ></el-image>
      </div>
      <div>
        <el-button class="updateImgae" type="info" @click="uploadImage"
          >Upload Customize Image</el-button
        >
      </div>
      <div class="edit_text" v-if="setEditDesign(productDetails.info.type)">
        <a :href="setEditDesignUrl" style="color: rgba(255, 35, 121, 0.65)">
          Edit design
        </a>
      </div>
    </div>
    <div class="selector_right">
      <div class="image_selection first_selection">
        <div class="title">Select title image view</div>
        <div class="image_list">
          <div
            :class="['image_box', viewIndex === index ? 'selected' : '']"
            v-for="(item, index) in colorList.list.img"
            :key="index"
            :style="{ backgroundImage: 'url(' + item.img400 + ')' }"
            @click="emit('changeViewIndex', index, item)"
          ></div>
        </div>
      </div>

      <div class="image_selection second_selection">
        <div class="title">Select title image color</div>
        <div class="image_list">
          <template
            v-for="(item, index) in productDetails.info.design_colors"
            :key="index"
          >
            <div
              class="color_name"
              @click="emit('sizeName', item, index)"
              :class="{ active_color_name: colorIndex === index }"
            >
              {{ item.name }}
            </div>
          </template>
        </div>
      </div>
    </div>
    <uploadCoverImage
      :uploadImageShow="uploadImageShow"
      @cancel="cancel"
      :tableData="tableData"
      @updateCoverImg="updateCoverImg"
    />
  </div>
</template>

<style scoped lang="scss">
$left_width: 208px;
.updateImgae {
  width: 100%;
  margin-top: 10px;
}

.jc {
  display: flex;
  justify-content: center;
}

:deep(.el-icon) {
  margin-right: 0 !important;
}
.active_color_name {
  border-color: #ff2379 !important;
}
.color_name {
  width: 80px;
  height: 30px;
  border: 1px solid;
  text-align: center;
  line-height: 30px;
  margin-left: 20px;
  margin-top: 10px;
  cursor: pointer;
}
.image_selector {
  display: flex;
  .selector_left {
    width: $left_width;
    .image_container {
      width: 208px;
      height: 205px;
      background-size: cover;
      background-position: center center;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      cursor: pointer;
      overflow: hidden;
    }
    .edit_text {
      font-size: 20px;
      color: rgba(255, 35, 121, 0.65);
      text-align: center;
      margin-top: 20px;
      text-indent: -15px;
      cursor: pointer;
      user-select: none;
    }
  }
  .selector_right {
    padding-left: 30px;
    flex: 1;
    .image_selection {
      .title {
        font-size: 20px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: bold;
      }
      .image_list {
        display: flex;
        overflow: hidden;
        margin-left: -20px;
        flex-wrap: wrap;
        .image_box {
          width: 92px;
          height: 92px;
          background-size: cover;
          background-position: center center;
          border: 2px solid #bbbbbb;
          border-radius: 6px;
          float: left;
          margin-left: 20px;
          margin-top: 15px;
          cursor: pointer;
          position: relative;
          // &:after {
          //   content: '';
          //   width: 20px;
          //   height: 20px;
          //   border-radius: 50%;
          //   border: 2px solid #bbbbbb;
          //   box-sizing: border-box;
          //   display: block;
          //   position: absolute;
          //   top: 6px;
          //   left: 6px;
          // }
          &.selected {
            border-color: #ff2379;
            // &:before {
            //   content: '';
            //   width: 10px;
            //   height: 10px;
            //   border-radius: 50%;
            //   background-color: #ff2379;
            //   display: block;
            //   position: absolute;
            //   top: 11px;
            //   left: 11px;
            // }
            // &:after {
            //   border-color: #ff2379;
            // }
          }
        }
      }
      & + .image_selection {
        margin-top: 20px;
      }
    }
  }
}
</style>
