import AWS from 'aws-sdk'

function createS3Client () {
    //密钥
    const credentials = {
        accessKeyId: 'AKIA6NJRBGNNYIAUM3WF',
        secretAccessKey: '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'
    }
    AWS.config.update(credentials)
    //区域
    AWS.config.region = process.env.VUE_APP_REGION

    return new AWS.S3({ params: { Bucket: process.env.VUE_APP_BUCKET_PRODUCT } })
}

const options = { partSize: 2048 * 1024 * 5, queueSize: 2, leavePartsOnError: true }
const s3 = createS3Client()

//分段上传文件
export const segmentedUploadFile = async (file, userCode, md5Str, designId) => {
    return new Promise(async (resolve, reject) => {
        if (!file || !file.name || !file.type) {
            return reject()
        }

        s3.upload({
            Key: `merchant/${userCode}/${designId}/cover/${md5Str}${file.name.substring(file.name.lastIndexOf('.'))}`,
            ContentType: file.type,
            Body: file,
        }, options).send(function (err, data) {
            if (err) {
                return reject(err)
            } else {
                return resolve(data)
            }
        })
    })
}
