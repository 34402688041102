<script setup>
import { ref, reactive, defineProps, watch, onMounted, computed } from 'vue'
import { getTagProductTemplateDisplayList } from '@/api/design'
import { useStore } from 'vuex'
// 引入
import TEditor from '@/components/TEditor.vue'
import { ElMessage } from 'element-plus'

const props = defineProps({
  titleProduct: String,
  describeProduct: String,
  priductType: String | Number,
  enableDesigner: Number,
  templateTags: Array,
  isOccasions: String | Number,
  real_time_preview: String | Number,
  remark: String,
})

watch(
  () => props.titleProduct,
  () => {
    formData.title = props.titleProduct
    formData.describe = props.describeProduct
    formData.checked = !!props.enableDesigner
    formData.tag = props.templateTags
    formData.homeShow = !!props.isOccasions
    formData.isPreview = !!props.real_time_preview
    formData.remark = props.remark
  }
)

let formData = reactive({
  title: '',
  describe: '',
  checked: true,
  homeShow: false,
  tag: [],
  isPreview: true,
  remark: '',
})
const descForm = ref(null)

const getData = async () => {
  try {
    await descForm.value.validate()
  } catch (e) {}

  return {
    productName: formData.title,
    describe: formData.describe,
    isEnableDesigner: formData.checked,
    tags: formData.tag,
    isRecommend: formData.homeShow,
    isPreview: formData.isPreview,
    remark: formData.remark,
  }
}

defineExpose({
  getData,
})
const store = useStore()

const isAdmin = computed(() => store.state.user?.userInfo?.isAdmin)

onMounted(() => {
  getTagList()
})
let options = ref([])

let getTagList = async () => {
  try {
    const { data } = await getTagProductTemplateDisplayList()
    options.value = data
  } catch (error) {
    ElMessage.error(error || error?.message)
  }
}

const value1 = ref([])
</script>

<template>
  <el-form ref="descForm" label-position="top" :model="formData">
    <el-form-item label="Title" prop="title" required>
      <el-input v-model="formData.title" />
    </el-form-item>
    <el-form-item
      label="Tags"
      v-if="
        !!isAdmin && (Number(priductType) === 2 || Number(priductType) === 3)
      "
    >
      <el-select
        v-model="formData.tag"
        multiple
        placeholder="Select"
        style="width: 100%"
      >
        <el-option
          v-for="item in options"
          :key="item.name"
          :label="item.name"
          :value="item.name"
        />
      </el-select>
    </el-form-item>
    <!-- designtype = 2 && designtype =3 显示是否在shopify显示 -->
    <el-form-item v-if="Number(priductType) === 2 || Number(priductType) === 3">
      <el-checkbox v-model="formData.checked"
        >Allow user personalization</el-checkbox
      >
    </el-form-item>
    <!-- 系统账号 && designtype =3  才显示 是否在首页显示-->
    <el-form-item v-if="!!isAdmin && Number(priductType) === 3">
      <el-checkbox v-model="formData.homeShow"
        >Display on landing page</el-checkbox
      >
    </el-form-item>
    <!--系统账号做成品编辑时，提供是否实时预览勾选项。 0 = 不预览 1 = 预览 -->
    <el-form-item v-if="!!isAdmin">
      <el-checkbox v-model="formData.isPreview">Live preview</el-checkbox>
    </el-form-item>
    <!-- 备注信息 -->
    <el-form-item label="Remark">
      <el-input v-model="formData.remark" type="textarea" />
    </el-form-item>

    <el-form-item label="Description" prop="describe">
      <!-- <el-input v-model="formData.describe" type="textarea" :rows="5" /> -->
      <TEditor
        ref="editor"
        v-model="formData.describe"
        :describe="formData.describe"
      />
    </el-form-item>
  </el-form>
</template>

<style scoped lang="scss"></style>
